import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import PayIcon1 from 'assets/rates/PayIcon1.svg';
import PayIcon2 from 'assets/rates/PayIcon2.svg';
import { openInfoNotification } from 'common/Notifications/Notifications';

import _ from './PayOption.module.css';

const PayOption = () => {
    const navigate = useNavigate();
    return (
        <div className={_.cardsWrapper}>
            <div className={clsx(_.card, _.blue)}>
                <div className={_.contentWrapper}>
                    <div className={_.iconWrapper}>
                        <img src={PayIcon1} alt='icon1' />
                    </div>
                    <div className={clsx(_.mainText, _.textWhite)}>Оплата банковской картой</div>
                    {/* <div className={clsx(_.secondText, _.textWhite)}>
                        Банковские карты, электронные деньги, Онлайн - банки, Сотовые операторы
                    </div> */}
                </div>
            </div>
            <div className={clsx(_.card, _.white)}>
                <div className={_.contentWrapper}>
                    <div className={_.iconWrapper}>
                        <img src={PayIcon2} alt='icon2' />
                    </div>
                    <div className={_.mainText}>Оплата по счету</div>
                    <div className={_.secondText}>
                        Пришлите на <span className={_.textBlue}>info@ailaw.ru</span> учетную карточку организации,
                        ссылку на проект, модуль, тариф, планируемый период использования — мы вышлем вам счет и
                        активируем доступ после оплаты
                        {/* <span
                            className={_.contractLink}
                            onClick={() => {
                                navigate('/editor');
                                // openInfoNotification('Регистрация и вход пока недоступны');
                                // stage prod напоминалка
                            }}
                        >
                            {' '}
                            договор
                        </span> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayOption;
