import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import _ from './Top.module.css';

const Top = () => (
    <div className={_.wrapper}>
        <div className={_.text}>
            {logoReplacer('Блог [[ailaw-dark]]')}
        </div>
    </div>
);

export default Top;
