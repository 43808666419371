const AiLawLight =
    '<span style="font-family: Manrope; font-weight: 800; display: inline-block;"><span style="margin-right: 0.11em; font-style: italic; color: rgb(47, 86, 134);">Ai</span><span style="color: white;">Law</span></span>';
const AiLawDark =
    '<span style="font-family: Manrope; font-weight: 800; display: inline-block;"><span style="margin-right: 0.11em; font-style: italic; color: rgb(47, 86, 134);">Ai</span><span style="color: black;">Law</span></span>';
const AiLawText = 'Ai Law';

function logoReplacer(text: string | undefined): string | JSX.Element;
function logoReplacer(text: string | undefined, type: 'dangerouslySetInnerHTML'): string;
function logoReplacer(text: string | undefined, type: 'plainText'): string;
function logoReplacer(text: string | undefined, type?: 'dangerouslySetInnerHTML' | 'plainText') {
    if (typeof text === 'string') {
        if (type === 'dangerouslySetInnerHTML') {
            return text
                .replaceAll('[[ailaw-dark]]', AiLawDark)
                .replaceAll('[[ailaw-light]]', AiLawLight);
        }
        if (type === 'plainText') {
            return text
                .replaceAll('[[ailaw-dark]]', AiLawText)
                .replaceAll('[[ailaw-light]]', AiLawText);
        }

        const arr = text.split(/(\[\[ailaw-light\]\]|\[\[ailaw-dark\]\])/g).map((el) => {
            if (el === '[[ailaw-light]]' || el === '[[ailaw-dark]]') {
                return (
                    <span style={{ fontWeight: 800, fontFamily: 'Manrope', display: 'inline-block' }}>
                        <span style={{ marginRight: '0.11em', fontStyle: 'italic', color: '#2f5686' }}>Ai</span>
                        <span
                            style={{
                                ...{ color: el === '[[ailaw-light]]' ? 'white' : 'black' },
                            }}
                        >
                            Law
                        </span>
                    </span>
                );
            }

            return el;
        });

        return <span>{arr}</span>;
    }
    return '';
}

export default logoReplacer;
